.schoolsSection {
  padding: 4rem 2rem;
  background-color: #121212;
  color: #ffffff;
}

.title {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  color: #FFA500;
}

.schoolsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.schoolCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ease;
}

.logoContainer {
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
}

.logo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.schoolName {
  text-align: center;
  font-size: 1.1rem;
  margin: 0;
  color: #ffffff;
}

/* Media Queries */
@media (max-width: 768px) {
  .schoolsSection {
    padding: 3rem 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .schoolsGrid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1.5rem;
  }

  .logoContainer {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 480px) {
  .schoolsSection {
    padding: 2rem 1rem;
  }

  .title {
    font-size: 1.8rem;
  }

  .schoolsGrid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
  }

  .logoContainer {
    width: 100px;
    height: 100px;
  }

  .schoolName {
    font-size: 1rem;
  }
} 